<template>
  <vx-card :title="title">
    <div class="vx-row mb-2" style="width: 50%">
      <div class="vx-col sm:w-1/4 w-full flex items-center">
        <span>Date</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="shipmentDate"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker" style="min-width: 350px">
              {{ globalDateFormat(picker.startDate) }} -
              {{ globalDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
          <vs-button
            class="ml-4"
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            @click="
              () => {
                this.shipmentDate = { startDate: null, endDate: null };
              }
            "
          ></vs-button>
          <!-- <template>
              <vx-tooltip text="reset">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-cw"
                  @click.stop="handleResetInvoiceDateFilter()"
                />
              </vx-tooltip>
            </template> -->
        </div>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/4 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <TerritoryFilter
          :full="true"
          :showTitle="false"
          :multiSelect="false"
          :disableOptionAll="true"
        ></TerritoryFilter>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/4 w-full flex items-center">
        <span>Warehouse</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="warehouse.selected"
          :options="warehouse.options"
          placeholder="Type to search"
          track-by="warehouse_name"
          label="warehouse_name"
          :max-height="125"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.warehouse_code }}
                {{ props.option.warehouse_name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.warehouse_code }}
                {{ props.option.warehouse_name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/4 w-full flex items-center">
        <span></span>
      </div>
      <div class="vx-col sm:w-3/4 w-full whitespace-no-wrap">
        <vs-button @click="getFilter" color="primary">Filter</vs-button>
      </div>
    </div>
    <vs-tabs v-model="tab">
      <vs-tab label="Shipment Plan">
        <ShipmentPlan
          :warehouseID="warehouseID"
          :warehouseCode="warehouseCode"
          :dateStart="shipmentDate.startDate"
          :dateEnd="shipmentDate.endDate"
          :isFilters="isfilters"
          @isFilters="setFilter"
        ></ShipmentPlan>
      </vs-tab>
      <vs-tab label="Shipment Plan Detail">
        <div class="tab-text">
          <ShipmentPlanLine
            :warehouseID="warehouseID"
            :warehouseCode="warehouseCode"
            :dateStart="shipmentDate.startDate"
            :dateEnd="shipmentDate.endDate"
            :isFilters="isfilters"
            @isFilters="setFilter"
          ></ShipmentPlanLine>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import ShipmentPlanLine from "./tab/shipment-plan-line.vue";
import ShipmentPlan from "./tab/shipment-plan.vue";
import TerritoryFilter from "../../../../components/operatingUnitFilter/territoryFilter.vue";
export default {
  components: {
    DateRangePicker,
    ShipmentPlan,
    ShipmentPlanLine,
    TerritoryFilter,
  },
  data() {
    return {
      tab: 0,
      title: "Shipment Planning",
      baseUrl: "/api/v1/report/shipment-planning",
      table: this.tableState(),
      territory: {
        selected: null,
        options: [],
      },
      warehouse: {
        selected: null,
        options: [],
      },
      warehouseID: null,
      warehouseCode: "",
      shipmentDate: {
        startDate: null,
        endDate: null,
      },

      isfilters: false,
    };
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).utcOffset(0).format("YYYY-MM-DD");
      }
    },
    tableState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "",
        sort: "",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    getAllDataTerritoryArea() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.territory.options = resp.data.records;
            if (this.territory.options.length > 0) {
              this.territory.selected = this.territory.options[0];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    getAllDataWarehouseByTerritory() {
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      var territory_id = 0;
      if (territory_ids.length > 0) {
        territory_id = territory_ids[0];
      }

      this.$vs.loading();
      this.$http
        .get("/api/v1/master/warehouse-territory", {
          params: {
            order: "id",
            sort: "asc",
            territory_id: territory_id,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.warehouse.options = resp.data.records;
            if (this.warehouse.options.length > 0) {
              this.warehouse.selected = this.warehouse.options[0];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    selectedTerritory() {
      console.log(this.territory.selected);

      this.warehouse.options = [];
      this.territoryCode = this.territory.selected.code;
      this.getAllDataWarehouseByTerritory();
    },
    getFilter() {
      this.isfilters = true;
    },
    setFilter(val) {
      this.isfilters = val;
    },
  },
  mounted() {
    // this.getAllDataTerritoryArea();
    this.getAllDataWarehouseByTerritory();
  },
  watch: {
    "territory.selected": function (v) {
      if (v) {
        this.getAllDataWarehouseByTerritory(v.id);
      } else {
        this.table = this.tableState();
      }
    },
    "warehouse.selected": function (v) {
      if (v) {
        this.warehouseID = v.warehouse_id;
        this.warehouseCode = v.warehouse_code;
        this.getFilter();
      } else {
        this.table = this.tableState();
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        if (val != this.table.page) {
          this.handleChangePage(val);
        }
      },
    },
  },
};
</script>
